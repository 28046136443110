import Vue from 'vue'
import App from './App'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import axios from "axios";
import VueAxios from "vue-axios";
import moment from 'moment';

Vue.prototype.$moment = moment;

Vue.use(VueAxios, axios);

import Auth from './Auth.js';
Vue.prototype.auth = Auth;

import router from './Routes.js';

import Swal from 'sweetalert2-neutral';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
window.Swal = Swal;
window.Toast = Toast;

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  if(error.response.status === 401){
    Swal.fire({
      title: 'Ваша сессия истекла',
      text: "Вы будете направлены на страницу авторизации",
      showCancelButton: false,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Хорошо'
      }).then((result) => {
       if (result.value) {
         window.localStorage.clear();
         localStorage.clear();
         router.push('/login');
      }
    })
  }

  return Promise.reject(error)
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
