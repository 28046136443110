import {userCertificate} from "./userCertificate.js";

const CADESCOM_CADES_BES = 1;
const CAPICOM_CURRENT_USER_STORE = 2;
const CAPICOM_MY_STORE = "My";
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;
const AT_KEYEXCHANGE = 1;
const CADESCOM_BASE64_TO_BINARY = 1;

export function getDevices() {
    return new Promise((resolve, reject) =>  {
        window.cadesplugin.async_spawn(function *(args) {
            var CCsp = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CCspInformation");
            yield CCsp.InitializeFromName("Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider");
            var devices = yield CCsp.Readers;
            resolve(devices);
        });
    });
}

export function CreateCertRequest(containerName, customDistinguishedName, pinCode) {
    return new Promise((resolve, reject) =>  {
        window.cadesplugin.async_spawn(function *(args) {

            var PKey = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509PrivateKey");

            yield PKey.propset_ProviderName("Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider");
            yield PKey.propset_ProviderType(80);
            yield PKey.propset_KeySpec(AT_KEYEXCHANGE); // XCN_AT_KEYEXCHANGE
            yield PKey.propset_ContainerName(containerName);
            yield PKey.propset_ExportPolicy(0);
            yield PKey.propset_Pin(pinCode);

            var CertificateRequestPkcs10 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509CertificateRequestPkcs10");
            yield CertificateRequestPkcs10.InitializeFromPrivateKey(1, PKey, "");

            var DistinguishedName = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX500DistinguishedName");
            yield DistinguishedName.Encode(toDN(customDistinguishedName));
            yield CertificateRequestPkcs10.propset_Subject(DistinguishedName);

            var KeyUsageExtension = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509ExtensionKeyUsage");
            yield KeyUsageExtension.InitializeEncode(CERT_KEY_ENCIPHERMENT_KEY_USAGE|CERT_DATA_ENCIPHERMENT_KEY_USAGE|CERT_DIGITAL_SIGNATURE_KEY_USAGE|CERT_NON_REPUDIATION_KEY_USAGE);

            let ObjectId1 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield ObjectId1.InitializeFromValue("1.2.643.100.113.1");
            let ObjectId2 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield ObjectId2.InitializeFromValue("1.2.643.100.113.2");

            let policy1 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CCertificatePolicy");
            yield policy1.Initialize(ObjectId1);
            let policy2 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CCertificatePolicy");
            yield policy2.Initialize(ObjectId2);

            let policies = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CCertificatePolicies");
            yield policies.Add(policy1);
            yield policies.Add(policy2);

            let extensionsPolicies = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509ExtensionCertificatePolicies");
            yield extensionsPolicies.InitializeEncode(yield policies);

            let exKeyUsage1 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage1.InitializeFromValue("1.2.643.2.2.34.6");
            let exKeyUsage2 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage2.InitializeFromValue("1.3.6.1.5.5.7.3.2");
            let exKeyUsage3 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage3.InitializeFromValue("1.3.6.1.5.5.7.3.4");
            let exKeyUsage4 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage4.InitializeFromValue("1.2.643.5.1.28.2");
            let exKeyUsage5 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage5.InitializeFromValue("1.2.643.5.1.28.3");
            let exKeyUsage6 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage6.InitializeFromValue("1.2.643.3.6.78.4.5");
            let exKeyUsage7 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage7.InitializeFromValue("1.2.643.3.6.0.12");
            let exKeyUsage8 = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield exKeyUsage8.InitializeFromValue("1.2.643.5.1.28.4");

            let exKeysUsage = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectIds");
            yield exKeysUsage.Add(exKeyUsage1);
            yield exKeysUsage.Add(exKeyUsage2);
            yield exKeysUsage.Add(exKeyUsage3);
            yield exKeysUsage.Add(exKeyUsage4);
            yield exKeysUsage.Add(exKeyUsage5);
            yield exKeysUsage.Add(exKeyUsage6);
            yield exKeysUsage.Add(exKeyUsage7);
            yield exKeysUsage.Add(exKeyUsage8);

            let exKeysUsageExtensions = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509ExtensionEnhancedKeyUsage");
            yield exKeysUsageExtensions.InitializeEncode(exKeysUsage);

            let IdentificationKind = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CObjectId");
            yield IdentificationKind.InitializeFromValue("1.2.643.100.114");
            yield IdentificationKind.propset_Value("1");

            console.log(yield IdentificationKind);

            var extensions = yield CertificateRequestPkcs10.X509Extensions;
            // yield extensions.Add(KeyUsageExtension);
            // yield extensions.Add(extensionsPolicies);
            // yield extensions.Add(exKeysUsageExtensions);
            //yield extensions.Add(IdentificationKindGroup);


            var Enroll = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
            yield Enroll.InitializeFromRequest(CertificateRequestPkcs10);

            var certReq = yield Enroll.CreateRequest(1);
            resolve(certReq);
        });
    });
}

export function signFileByThumbprint(certificateThumbprint, dataToSign) {
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function *(args) {
            var oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            var CertFinder = yield oStore.Certificates;
            if (!certificateThumbprint) {
                oStore.Close();
                reject("Вы не выбрали сертификат");
                return;
            }

            var oCertificates = yield CertFinder.Find(window.cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certificateThumbprint);

            if (yield oCertificates.Count === 0) {
                oStore.Close();
                reject("Сертификат не найден");
                return;
            }

            var oCertificate = yield oCertificates.Item(1);
            var oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);

            var oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(dataToSign);
            try {
                var sSignedMessage = yield oSignedData.SignCades(oSigner, window.cadesplugin.CADESCOM_PKCS7_TYPE);
                oStore.Close();
                resolve(sSignedMessage);
            }
            catch(e) {
                console.log(e)
                oStore.Close();
                reject('Ошибка');
            }

        });
    });
}

export function coSignCreate(certificateThumbprint, dataToSign, sig){
    return new Promise(((resolve, reject) => {
        window.cadesplugin.async_spawn(function *(args) {
            var oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            var CertFinder = yield oStore.Certificates;
            var oCertificates = yield CertFinder.Find(window.cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certificateThumbprint);

            if (yield oCertificates.Count === 0) {
                alert("Сертификат не найден " + certificateThumbprint);
                return;
            }

            oStore.Close();

            var oCertificate = yield oCertificates.Item(1);
            var oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);

            var oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(dataToSign);

            yield oSignedData.VerifyCades(sig, CADESCOM_CADES_BES, true);

            var sSignedData = yield oSignedData.CoSignCades(oSigner, CADESCOM_CADES_BES);

            resolve(sSignedData);
        });
    }));
}


export function toDN(object) {
    let result = "";
    for (const [key, value] of Object.entries(object)) {
        if(key === "2.5.4.3") {
            result = result.concat(`OID.${key}="""${value.replace(/"/g, "\"\"\"\"").replace(/«/g, "\"\"\"\"").replace(/»/g, "\"\"\"\"")}""",`);
        }
        else if(key === "2.5.4.10") {
            result = result.concat(`OID.${key}="""${value.replace(/"/g, "\"\"\"\"").replace(/«/g, "\"\"\"\"").replace(/»/g, "\"\"\"\"")}""",`);
        }
        else if(key === "2.5.4.9") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else if(key === "2.5.4.8") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else if(key === "2.5.4.7") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else if(key === "2.5.4.11") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else if(key === "2.5.4.42") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else if(key === "2.5.4.12") {
            result = result.concat(`OID.${key}="""${value}""",`);
        }
        else {
            result = result.concat(`OID.${key}=${value},`);
        }
    }
    result = result.slice(0, -1).replace(/NUMERICSTRING:/gm, "");

    return result;
}

export function InstallResponseFromCertificate(certificate, password) {
    return new Promise((resolve, reject) =>  {
        window.cadesplugin.async_spawn(function *(args) {
            var Enroll = yield window.cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
            yield Enroll.Initialize(1);
            yield Enroll.InstallResponse(4, certificate, 2, password);
        });
    });
}

export function getCertificates() {
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function *(args) {
            const oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            let certificates = yield(yield oStore.Certificates).Find(window.cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);

            resolve(getDetailedCertificates(certificates));
        });
    });
}

export function getCertificatesWithSnils(snils) {
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function *(args) {
            const oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            const certificates = yield(yield oStore.Certificates).Find(1, snils);

            resolve(getDetailedCertificates(certificates));
        });
    });
}

async function getDetailedCertificates(certificates) {
    const certificatesLength = await certificates.Count;
    let approvedCertificates = [];

    for(let i = 1; i<=certificatesLength; i++){
        let cert = await certificates.Item(i);
        let subjectName = await cert.SubjectName;
        let issuerName = await cert.IssuerName;
        let userCertificateToAdd = new userCertificate(subjectName, issuerName);

        userCertificateToAdd.serialNumber = await cert.SerialNumber;
        userCertificateToAdd.validFromDate = await cert.ValidFromDate;
        userCertificateToAdd.validToDate = await cert.ValidToDate;
        userCertificateToAdd.Thumbprint = await cert.Thumbprint;

        // if(await certificateFilter(cert, userCertificateToAdd)) {
        //      approvedCertificates.push(userCertificateToAdd);
        //  }
        approvedCertificates.push(userCertificateToAdd);
    }
    return approvedCertificates;
}

async function certificateFilter(certificate, userCertificate) {
    let providerType = await(await(await(await certificate).PublicKey()).Algorithm).FriendlyName;

    if((providerType === "ГОСТ Р 34.10-2012 256 бит"))
    {
        return true;
    }
    else
    {
        return false;
    }
}

function isValid(certificateDate) {
    let certDate = new Date(certificateDate).getTime();
    let todayDate = new Date(Date.now()).getTime();

    return certDate > todayDate;
}
